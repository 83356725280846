var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('p'),_c('v-divider'),_c('p'),_c('div',{staticClass:"margin-bottom-large"},[_c('h6',{staticClass:"text-h6"},[_vm._v(" I have an excel or csv file: ")]),_c('p'),_c('ol',[_c('li',[_c('p',[_vm._v(" Go to "),(_vm.$router)?_c('router-link',{attrs:{"to":"/"}},[_c('span',[_vm._v("tableandmap.com")])]):_c('a',{attrs:{"href":"/"}},[_c('span',[_vm._v("tableandmap.com")])]),_vm._v(" and click "),_c('b',[_vm._v("Upload a dataset")]),_vm._v(". ")],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('h6',{staticClass:"text-h6"},[_vm._v(" I want to copy and paste in data: ")]),_c('p'),_c('ol',[_c('li',[_c('p',[_vm._v(" Go to "),(_vm.$router)?_c('router-link',{attrs:{"to":"/"}},[_c('span',[_vm._v("tableandmap.com")])]):_c('a',{attrs:{"href":"/"}},[_c('span',[_vm._v("tableandmap.com")])]),_vm._v(" and click "),_c('b',[_vm._v("Paste")]),_vm._v(". ")],1)]),_vm._m(5),_vm._m(6)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h5',{staticClass:"text-h5"},[_c('p',[_vm._v(" Whether your file has latitudes and longitudes or only contains addresses, "),_c('b',[_vm._v("Table & Map")]),_vm._v(" makes it easy to map your location data. Follow the guide below and easily get started. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Select an excel or csv file with location data from your computer. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_c('b',[_vm._v("Table & Map")]),_vm._v(" will process your file. The larger the file, the longer this step takes to complete. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("If you "),_c('u',[_vm._v("do")]),_vm._v(" have a latitude and longitude in the file:")]),_c('p',[_vm._v(" a. The system will attempt to automatically detect the columns and if successful, you will enter the tool automatically. ")]),_c('p',[_vm._v(" b. If the system needs some help, it will prompt you to select the correct latitude and longitude columns. After selecting the columns, press "),_c('b',[_vm._v("Confirm")]),_vm._v(" and you will enter the tool. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("If you "),_c('u',[_vm._v("do not")]),_vm._v(" have a latitude and longitude in the file:")]),_c('p',[_vm._v(" a. The system will ask you to select the address components (address, city, state or zip code). You must select at least one address component to continue, but the more you select, the more accurate the geocoding will be. ")]),_c('p',[_vm._v(" b. Press "),_c('b',[_vm._v("Confirm")]),_vm._v(" and the system will compute the latitude and longitude for each record and add them as new columns to your dataset. ")]),_c('p',[_vm._v(" c. You will enter the tool automatically when the geocoding process is complete. The duration of this step depends on the number of records in the dataset. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v("Copy the dataset and paste it into the text box.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',[_vm._v(" Press "),_c('b',[_vm._v("Confirm")]),_vm._v(" and continue with Step 3 of the "),_c('i',[_vm._v("I have an excel or csv file")]),_vm._v(" instructions above. ")])])
}]

export { render, staticRenderFns }